
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import SelectComponent from '@/components/SelectHackmetrix/Index.vue'

@Component({
  components: {
    SelectComponent,
  },
})
export default class DialogTemplatesComponent extends Vue {
  @Prop({ required: true }) readonly showDialog!: string
  @Prop({ required: false, default: false }) readonly showSelect!: string
  @Prop({ required: false }) readonly selectLabel!: string
  @Prop({ required: false }) options!: Array<any>
  @Prop({ required: false }) readonly title!: string
  @Prop({ required: false }) readonly subtitle!: string
  @Prop({ required: false }) readonly danger!: string
  @Prop({ required: false }) readonly action!: string
  @Prop({ required: false, default: 'Cancelar' }) readonly cancelButton!: string

  private dialog = false
  private valueSelect = null

  @Watch('showDialog')
  show(val: boolean): void {
    if (val) {
      this.dialog = true
    }
  }

  @Watch('dialog')
  dialogClose(): void {
    if (this.dialog === false) {
      this.$emit('close')
    }
  }

  private value(value: any) {
    this.valueSelect = value
    this.$emit('value', value)
  }

  private emitEvent() {
    if (!this.showSelect || (this.showSelect && this.valueSelect != null)) {
      this.dialog = false
    }

    this.valueSelect == null
    this.$emit('action')
  }
}
